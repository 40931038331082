'use client';

// icons
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
// utils
import cssStyles from 'src/utils/cssStyles';
// routes
// components
import { Iconify } from 'src/components/landing';
import { MotionViewport } from 'src/components/animate';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useSignupContext } from '../../../components/signup-popup/context/signup-context';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgImage({
    startColor: alpha(theme.palette.grey[900], 0.8),
    endColor: alpha(theme.palette.grey[900], 0.8),
  }),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 960,
  margin: 'auto',
  textAlign: 'center',
  padding: theme.spacing(7, 3, 7, 3),
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12.5, 2, 11, 2),
  },
}));

// ----------------------------------------------------------------------

export default function HomeSimpleSlogan({
                                           caption = null,
                                           title = 'Don\'t Waste Your Ad Budgets',
                                           description = null,
                                           buttonLabel = 'Get Tracklution Now',
                                           demoButtonVisible = false,
                                           redirect = null,
                                         }) {
  const signup = useSignupContext();

  return (
    <MotionViewport>
      <RootStyle>
        <ContentStyle>
          <Stack spacing={1}>
            {caption && <Typography variant='h5'>
              {caption}
            </Typography>}

            <Typography variant='h2' sx={{ lineHeight: 1.2 }}>
              {title}
            </Typography>

            {description && <Typography variant='body1' sx={{ mt: 1.5, fontSize: { xs: '0.9rem', md: '1rem' }, mb: { xs: -1, md: 0 } }}>
              {description}
            </Typography>}

            <Stack spacing={1} sx={{ mt: { xs: 3.5, md: 5 } }}>
              <Button
                size='large'
                variant='contained'
                endIcon={<Iconify icon='carbon:chevron-right' />}
                rel='noopener'
                color='accent'
                onClick={() => signup.onToggle('register', redirect)}
                sx={{ mx: 'auto', px: { xs: 3, md: 6 }, py: { xs: 3, md: 3.5 }, fontSize: { xs: '1rem', md: '1.2rem' } }}
              >
                {buttonLabel}
              </Button>
              <Typography variant="body2">No Credit Card needed.</Typography>
              {demoButtonVisible && <Button
                size='large'
                rel='noopener'
                onClick={() => signup.onToggle('contact')}
                sx={{ mx: 'auto', textTransform: 'none', mb: -2 }}
              >
                Request a demo
              </Button>}
            </Stack>
          </Stack>
        </ContentStyle>
      </RootStyle>
    </MotionViewport>
  );
}

HomeSimpleSlogan.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  caption: PropTypes.string,
  buttonLabel: PropTypes.string,
  demoButtonVisible: PropTypes.bool,
  redirect: PropTypes.string,
};
