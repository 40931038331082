'use client';

import { useState } from 'react';
// icons
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Button,
  Accordion,
  Container,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
// components
import { Iconify } from 'src/components/landing';

// ----------------------------------------------------------------------

const CONTENTS = [
  {
    question: `Which ad traffic channels do you support?`,
    answer: `We support the most popular marketing platforms, such as Google Ads, Meta / Facebook and Instagram Ads, and TikTok Ads. We are working all the time on adding more integrations, and as a Tracklution user, you are the first one to gain access to the newest platform additions.`,
  },
  {
    question: 'How difficult is the setup?',
    answer: 'Thanks to our developer wizards the setup is quick and easy – it literally only takes 5 minutes. 🧙You don’t need to write a single line of code or bother your developer team. If you have access to your Ad Accounts, such as Meta Ads and Google Ads, you’re good to go. We offer detailed instructions and fast support if you need any help.',
  },
  {
    question: 'How does Tracklution differ from just installing the pixels offered by the Ad Platform?',
    answer: <>Tracklution pixels can be installed just as simply as any other pixel, by inserting pixels into your website, utilising tools like
      Google Tag Manager. But instead of 3rd party cookies, Tracklution collects 1st party data, and thus is not being blocked by ad blockers and
      browsers that usually natively block 3rd party cookies. Many Tracklution users have seen conversion reporting towards ad channels increase from
      40-70% coverage to 100% coverage.<br /><br />With Tracklution, you <b>only install pixels once</b> to your website, after which it is seamlessly
      connected and collects data to all your Ad Accounts connected to Tracklution. With Tracklution you benefit from 1st-party cookies and Conversion
      APIs without writing a single line of code.</>,
  },
  {
    question: 'What is the uplift in conversion tracking?',
    answer: <>Based on the data we have received from Tracklution users, Tracklution and its server-side tracking has improved tracking with 19 – 38%
      compared to basic pixel-only, 3rd-party cookie (client-based) tracking. Tracklution will minimise the effect of ad blockers and cookie-blocking
      browsers, as those work on client-side, not server-side.
      <br /><br />
      Also those customers who already had a custom-built server-side tracking before starting using Tracklution, see benefit in improved conversion
      attribution, as well as the quickness and easiness of setting up new ad channels via Tracklution. No more fighting over developer resources to
      get proper tracking on your new Ad Ventures! 🤩
    </>,
  },
  {
    question: 'Do you support Offline Conversions?',
    answer: 'Yes, Tracklution automatically uploads offline conversions to the connected ad platforms via their Conversion APIs using the collected 1st-party data. You can deliver offline conversions from other systems by using webhook.',
  },
  {
    question: 'Do you have plans for agencies?',
    answer: 'Yes, hit us with an email to anni@tracklution.com and we’ll be happy to help you get started!',
  },
  {
    question: 'Are you GDPR compliant?',
    answer: 'Yes. All of our data and servers are located within the EU, specifically in Stockholm. The product is designed and built in the EU.',
  },
  {
    question: 'How do I get started?', answer: <>The simplest way to get started is to create your Tracklution account here.
      <br /><br />
      We have an onboarding guide that will take you through the setup process. You don’t need any technical knowledge or developer team, as long as
      you have access to your ad accounts, such as Facebook Ads, Google Ads and TikTok Ads. You can also reach out to our team of experts in case you
      need any help.
    </>,
  },
];

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(6.5, 0, 2.5, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(11, 0, 2, 0),
  },
  zoom: 0.9,
}));

export default function HomeFAQs() {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={{ md: 2 }} justifyContent='center'>
          <Grid item xs={12} md={10}>
            <Typography variant='h2' sx={{ textAlign: 'center', lineHeight: 1.2 }}>
              Frequently Asked Questions
            </Typography>

            <Box sx={{ my: { xs: 3, md: 10 }, mx: -1 }}>
              {CONTENTS.map((faq) => (
                <Accordion
                  expanded={expanded === faq.question}
                  onChange={handleChangeExpanded(faq.question)}
                  sx={{
                    px: { xs: 0, md: 2 }, py: expanded === faq.question ? { xs: 1, md: 2 } : 1,
                    '&:before': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  key={faq.question}
                >
                  <AccordionSummary>
                    <Typography variant='h5' sx={{ flexGrow: 1 }}>
                      {faq.question}
                    </Typography>
                    <Iconify
                      icon={expanded === faq.question ? 'carbon:subtract' : 'carbon:add'}
                      sx={{ width: 24, height: 24 }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>

            {false && <Box
              sx={{
                borderWidth: 1,
                borderRadius: 3,
                textAlign: 'center',
                borderStyle: 'dashed',
                borderColor: 'grey.50032',
                px: { xs: 3, md: 5 },
                py: { xs: 6, md: 8 },
              }}
            >
              <Typography variant='h3'>Still Have Questions?</Typography>

              <Typography sx={{ mt: 3, mb: 5, color: 'text.secondary' }}>
                Please describe your case to receive the most accurate advice.
              </Typography>
              <Button
                size='large'
                color='inherit'
                variant='contained'
                href='mailto:support@minimals.cc?subject=[Feedback] from Customer'
              >
                Contact us
              </Button>
            </Box>}
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
